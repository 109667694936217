.add-trip-container, .edit-trip-container {
    display: flex;
  }
  
  .add-trip-content, .edit-trip-content {
    margin-left: 20%;
    padding: 20px;
    width: 100%;
  }
  
  .add-trip-content h2, .edit-trip-content h2 {
    margin-bottom: 20px;
  }
  
  .add-trip-content form, .edit-trip-content form {
    display: flex;
    flex-direction: column;
  }
  
  .add-trip-content form label, .edit-trip-content form label {
    margin-bottom: 10px;
  }
  
  .add-trip-content form input, .edit-trip-content form input,
  .add-trip-content form select, .edit-trip-content form select,
  .add-trip-content form textarea, .edit-trip-content form textarea {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
  }
  
  .add-trip-content form > button, .edit-trip-content form > button {
    padding: 10px 15px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .add-trip-content form > button:hover, .edit-trip-content form > button:hover {
    background-color: #0056b3;
  }
  
  .steps-section {
    margin-top: 20px;
  }
  
  .steps-section .step-item {
    background: #f9f9f9;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
  }
  
  .steps-section .step-item h4 {
    margin-top: 0;
  }
  
  .steps-section .add-step-form {
    background: #f1f1f1;
    padding: 10px;
    border-radius: 4px;
  }
  
  .steps-section .add-step-form label {
    margin-bottom: 5px;
  }
  
  .steps-section .add-step-form input, .steps-section .add-step-form textarea, .steps-section .add-step-form select {
    margin-bottom: 10px;
  }
  
  .steps-section .add-step-form button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .steps-section .add-step-form button:hover {
    background-color: #218838;
  }

  .add-step-form { background:#fff; border-radius:8px; margin-bottom:15px; padding:15px; }
  .step-button { margin:auto; }
  .image-preview, .video-preview {
    border:1px solid #e0e0e0;
    border-radius:8px;
    display: inline-block;
    width: 100px;
    height: 100px;
    overflow:hidden;
    object-fit: cover;
    margin: 10px 2px 10px 0;
  }

  .video-preview {
    max-width: 100%;
    max-height: 200px;
  }

  .spots-list {
    margin-top: 10px;
  }

  .spots-list > div {
    border:1px solid #e0e0e0;
    border-radius:8px;
    cursor:pointer;
    display: inline-block;
    width: 100px;
    height: 100px;
    overflow:hidden;
    position:relative;
    margin: 10px 2px 10px 0;
  }
  .spots-list > div img {
    display:block;
    object-fit: cover;
    position:absolute;
    left:0;
    height:100%;
    top:0;
    width:100%;
    z-index:0;
  }
  .spots-list > div > span {
    align-items:center;
    background:rgba(0,0,0,0.3);
    color:#fff;
    display:flex;
    height:100%;
    justify-content:center;
    position:relative;
    text-overflow: ellipsis;
    white-space:nowrap;
    width:100%;
    z-index:1;
  }
  
  .spots-list button {
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 5px 10px;
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .spots-list button:hover {
    background-color: #e1e1e1;
  }
  
  .selected-spots {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
  }
  
  .selected-spot {
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 5px 10px;
    background-color: #e0ffe0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .selected-spot img.spot-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 10px;
  }
  
  .selected-spot button {
    margin-left: 10px;
    padding: 3px 6px;
    background-color: #ff6666;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
  }
  
  .selected-spot button:hover {
    background-color: #ff3333;
  }