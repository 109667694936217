.categories-container {
    display: flex;
  }
  
  .categories-content {
    margin-left: 20%;
    padding: 20px;
    width: 100%;
  }
  
  .categories-content h2 {
    margin-bottom: 20px;
  }
  
  .categories-content .add-category-button {
    display: inline-block;
    padding: 10px 15px;
    margin-bottom: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    text-decoration: none;
  }
  
  .categories-content .add-category-button:hover {
    background-color: #0056b3;
  }
  
  .categories-content ul {
    list-style-type: none;
    padding: 0;
  }
  
  .categories-content li {
    background: #fff;
    margin: 10px 0;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
  }
  
  .categories-content li h3 {
    margin: 0 10px 0 0;
  }
  
  .categories-content li img {
    max-width: 50px;
    max-height: 50px;
    margin-right: 10px;
  }

  .categories-container .image-preview { display:block; margin:0; }