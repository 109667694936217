.login-container { 
    align-items:center; display:flex; flex-direction:column; height:100%; justify-content:center;
}
.login-container .logo { margin-bottom:30px; }
.login-container .box {
    align-items:center; background:#fff; border-radius:15px; display:flex; flex-direction:column; justify-content:center; padding:20px;
    box-shadow:0 0 20px rgba(0,0,0,0.2);
}
.login-container h2 { margin:0 0 20px 0; }
.login-container form { display:flex; flex-direction:column; }
.login-container form > input,
.login-container form > button { margin:15px 0 0 0; padding:10px; }