* { box-sizing:border-box; }

html, body { height:100vh; }

/* General styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

h2 {
  color: #333;
}

/* Container styles */
.container {
  padding: 20px;
}

.form-container {
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li h3 {
  margin: 0;
}

form > label,
.onlyDev > label,
.add-step-form > label { 
  align-items:center;
  background:#ddd;
  border:1px solid #e0e0e0;
  border-radius:8px;
  display:flex;
  padding:0 10px;
}

form > label > span,
.onlyDev > label > span,
.add-step-form > label > span {
  font-size:14px;
  flex:1;
}
form > label > span + div,
.onlyDev > label > span + div,
.add-step-form > label > span + div { background:#fff; }

form label > div,
form label > input,
form label > textarea,
form label > select {
  background:#fff;
  border:none !important;
  border-left:1px solid #e0e0e0 !important;
  border-radius:0 !important;
  flex:1;
  margin:0 !important;
  padding:20px !important;
  resize:none;
}
form label > textarea { min-height:150px; }
form label > div {
  display:flex;
  flex-direction:column;
}

select {
  background:#fff;
  border:1px solid #e0e0e0;
  border-radius:8px !important;
  margin:0 !important;
  padding:10px !important;
  resize:none;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  margin-left: 5px;
  border-radius: 4px;
  cursor: pointer;
}

button.inactive { background-color:#c0c0c0; }

button:hover {
  background-color: #0056b3;
}
button.inactive:hover { background-color:#a0a0a0; }

button.red { background-color:#d9534f !important; }
button.red:hover { background-color:#c9302c !important; }
button.back { background-color:#8f4fd9 !important; }
button.back:hover { background-color:#702cc9 !important; }

form > input, form > select {
  margin-right: 10px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

form > button {
  padding: 5px 10px;
}

.trs05 {
  transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
  -ms-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  -webkit-transition: 0.5s all ease;
}

#root { height:100vh; }

/* Dashboard styles */
.dashboard-container {
  height:100%;
}

.dashboard-content {
  height:100%;
  margin-left: calc(20% + 1px + 20px);
  padding: 20px;
}

.users-content,
.spots-content,
.categories-content,
.user-details-content,
.trips-content {
  margin-left: calc(20% + 1px + 20px); /* Adjust according to sidebar width */
  padding: 20px;
}

.table { border:1px solid #ccc; width:100%; }

.table th { background:#333; color:#fff; padding:10px; text-align:left; }
.table.sortable th { cursor:pointer; position:relative; }
.table.sortable th::after { content:"-"; position:absolute; right:15px; }
.table.sortable th.desc::after { content:"▲"; }
.table.sortable th.asc::after { content:"▼"; }

.table td { background:#fff; padding:10px; }
.table td.center { text-align:center; }
.table td.text-right { text-align:right; }
.table tr:nth-child(even) td { background:#e0e0e0; }

#loading { align-items:center; background-image:url('../public/loading.gif'); background-position:center; background-repeat:no-repeat; background-size:100px; display:flex; height:100%; justify-content:center; }


.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  outline: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.close-button {
  background: #d9534f;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-button:hover {
  background: #c9302c;
}

.modal-image {
  max-width: 100%;
  max-height: 80vh;
  display: block;
  margin: 0 auto;
}

#filters {
  background:#fff;
  border-radius:8px;
  margin-bottom:15px;
  padding:15px;
}

.filters { margin-top:10px; }
.filters:first-child { margin-top:0; }
#filters form label > input,
#filters form label > select { padding:10px !important; }

.top-header { align-items:center; display:flex; }
.top-header h2 { margin-right:auto; }

.onlyDev { background:#72addd; border-radius:8px; margin-bottom:15px; padding:15px; }

.existing-images { display:flex; margin-bottom:15px; }
.preview-img-container { border:1px solid #e0e0e0; border-radius:8px; overflow:hidden; position:relative; }
.preview-img-container img { display:block; }
.preview-img-container .img-button-container {
  align-items:center;
  background:rgba(0,0,0,0.4);
  display:none;
  flex-direction:column;
  height:100%;
  justify-content:center;
  left:0;
  position:absolute;
  top:0;
  width:100%;
}
.preview-img-container .img-button-container button { margin:0 0 5px 0; }
.preview-img-container:hover .img-button-container { display:flex; }

@media screen and (max-width: 767px) {
  html, body, #root { height:100%; }
  #filters { overflow:auto; }
  .edit-spot-content { margin:0; }
  .users-content, .spots-content, .categories-content, .user-details-content, .trips-content, .dashboard-content { margin:0; overflow:auto; }
  .sidebar {
    transition:0.3s left ease;
    left:-80%;
    height:100%;
    position:fixed;
    top:0;
    width:80%;
    z-index:99;
  }
  .sidebar .button-menu { 
    display:block;
    background:#f4f4f4;
    border-radius:0 100% 100% 0;
    border:1px solid #ddd;
    box-shadow:0 0 20px 5px rgba(0,0,0,0.2);
    font-size:10px;
    font-weight:700;
    height:50px;
    line-height:50px;
    position:absolute;
    right:-50px;
    text-align:center;
    top:50%;
    width:50px;
  }
  .sidebar.open { left:0; }
}