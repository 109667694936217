.user-details-container {
  display: flex;
}
  
.user-details-content h2 {
  margin-bottom: 20px;
}

.user-details-content form {
  display: flex;
  flex-direction: column;
}

.user-details-content form label {
  margin-bottom: 10px;
}

.user-details-content form input,
.user-details-content form textarea,
.user-details-content form select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
}

.user-details-content form button {
  padding: 10px 15px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}

.user-details-content form button:hover {
  background-color: #0056b3;
}

.image-preview {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.image-preview img {
  max-width: 100px;
  max-height: 100px;
  object-fit: contain;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.image-preview img.banner { max-width:200px; }