.spots-container {
    display: flex;
  }
  
  .spots-content {
    margin-left: 20%;
    padding: 20px;
    width: 100%;
  }
  
  .spots-content h2 {
    margin-bottom: 20px;
  }
  
  .spots-content .add-spot-button {
    display: inline-block;
    padding: 10px 15px;
    margin-bottom: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    text-decoration: none;
  }
  
  .spots-content .add-spot-button:hover {
    background-color: #0056b3;
  }
  
  .spots-content ul {
    list-style-type: none;
    padding: 0;
  }
  
  .spots-content li {
    background: #fff;
    margin: 10px 0;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
  }
  
  .spots-content li h3 {
    margin: 0 10px 0 0;
  }
  
  .spots-content li img {
    max-width: 50px;
    max-height: 50px;
    margin-right: 10px;
  }
  
  .spots-container .image-preview { display: block; margin: 0; }
  
  /* Styles for the modal */
  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 8px;
    outline: none;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  .close-button {
    background: #d9534f;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .close-button:hover {
    background: #c9302c;
  }
  
  .modal-image {
    max-width: 100%;
    max-height: 80vh;
    display: block;
    margin: 0 auto;
  }
  