.dashboard-container {
    display: flex;
  }
  
  .dashboard-content {
    margin-left: 20%;
    padding: 20px;
    width: 100%;
  }
  
  .dashboard-content h2 {
    margin-bottom: 20px;
  }
  
  .stats-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .stat-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    flex: 1;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .stat-card h3 {
    margin-bottom: 10px;
  }
  
  .stat-card p {
    font-size: 24px;
    font-weight: bold;
  }
  