
/* Error message styles */
.error-message {
    background-color: #f8d7da;
    color: #721c24;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
}

.success-message {
    background-color: #d7f8da;
    color: #1c721c;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
}