.edit-category-container {
    display: flex;
  }
  
  .edit-category-content {
    margin-left: 20%;
    padding: 20px;
    width: 100%;
  }
  
  .edit-category-content h2 {
    margin-bottom: 20px;
  }
  
  .edit-category-content form {
    display: flex;
    flex-direction: column;
  }
  
  .edit-category-content form label {
    margin-bottom: 10px;
  }
  
  .edit-category-content form input,
  .edit-category-content form select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
  }
  
  .edit-category-content form button {
    padding: 10px 15px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .edit-category-content form button:hover {
    background-color: #0056b3;
  }
  