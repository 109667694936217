.sidebar {
    width: 20%;
    background: #f4f4f4;
    padding: 15px;
    border-right: 1px solid #ddd;
    position: fixed;
    height: 100%;
  }
  
  .sidebar .button-menu { display:none; }

  .sidebar h3 {
    margin-top: 0;
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar ul li {
    margin: 10px 0;
  }
  
  .sidebar ul li a {
    background: #fff;
    margin: 10px 0;
    padding: 10px;
    border-radius: 4px;
    display:block;
    text-decoration: none;
    overflow:hidden;
    position:relative;
  }
  
  .sidebar ul li a::before {
    transition: 0.5s all ease;
    -o-transition: 0.5s all ease;
    -ms-transition: 0.5s all ease;
    -moz-transition: 0.5s all ease;
    -webkit-transition: 0.5s all ease;
    content:attr(data-name);
    color:#000;
    font-size:12px;
    font-weight:700;
    position:relative;
    text-transform:uppercase;
    z-index:1;
  }
  .sidebar ul li a::after {
    transition: 0.5s all ease;
    -o-transition: 0.5s all ease;
    -ms-transition: 0.5s all ease;
    -moz-transition: 0.5s all ease;
    -webkit-transition: 0.5s all ease;
    background: rgb(110,217,158);
    background: -moz-linear-gradient(90deg, rgba(110,217,158,1) 0%, rgba(120,222,226,1) 50%, rgba(69,162,252,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(110,217,158,1) 0%, rgba(120,222,226,1) 50%, rgba(69,162,252,1) 100%);
    background: linear-gradient(90deg, rgba(110,217,158,1) 0%, rgba(120,222,226,1) 50%, rgba(69,162,252,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6ed99e",endColorstr="#45a2fc",GradientType=1);
    bottom:0;
    content:" ";
    left:0;
    position:absolute;
    right:100%;
    top:0;
    z-index:0;
  }
  .sidebar ul li a:hover::before,
  .sidebar ul li a.active::before { color:#fff; }
  .sidebar ul li a:hover::after,
  .sidebar ul li a.active::after { right:0; }

  .sidebar ul li a.red {
    transition: 0.5s all ease;
    -o-transition: 0.5s all ease;
    -ms-transition: 0.5s all ease;
    -moz-transition: 0.5s all ease;
    -webkit-transition: 0.5s all ease;
    background:rgb(155, 19, 19);
  }
  .sidebar ul li a.red:hover { background:rgb(117, 12, 12); }
  .sidebar ul li a.red::after { display:none; }
  .sidebar ul li a.red::before { color:#fff; }

  .sidebar ul li a:hover {
    text-decoration: none;
  }
  

  .logout-button {
    transition: 0.5s all ease;
    -o-transition: 0.5s all ease;
    -ms-transition: 0.5s all ease;
    -moz-transition: 0.5s all ease;
    -webkit-transition: 0.5s all ease;
    display: block;
    width: 100%;
    padding: 10px;
    margin: 0;
    background-color: #d9534f;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
  }
  
  .logout-button:hover {
    background-color: #c9302c;
  }