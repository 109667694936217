.edit-trip-container {
    display: flex;
  }
  
  .edit-trip-content {
    margin-left: 20%;
    padding: 20px;
    width: 100%;
  }
  
  .edit-trip-content h2 {
    margin-bottom: 20px;
  }
  
  .edit-trip-content form {
    display: flex;
    flex-direction: column;
  }
  
  .edit-trip-content form label {
    margin-bottom: 10px;
  }
  
  .edit-trip-content form input,
  .edit-trip-content form select,
  .edit-trip-content form textarea {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
  }
  
  .edit-trip-content form button {
    padding: 10px 15px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .edit-trip-content form button:hover {
    background-color: #0056b3;
  }
  
  .existing-images .preview-img-container {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .existing-images .preview-img-container img {
    display: block;
  }
  
  .existing-images .img-button-container {
    align-items: center;
    background: rgba(0,0,0,0.4);
    display: none;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  
  .existing-images .preview-img-container:hover .img-button-container {
    display: flex;
  }
  
  .existing-images .img-button-container button {
    margin: 5px;
  }
  
  .steps-section {
    margin-top: 20px;
  }
  
  .steps-section .step-item {
    background: #f9f9f9;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
  }
  
  .steps-section .step-item h4 {
    margin-top: 0;
  }
  
  .steps-section .add-step-form {
    background: #f1f1f1;
    padding: 10px;
    border-radius: 4px;
  }
  
  .steps-section .add-step-form label {
    margin-bottom: 5px;
  }
  
  .steps-section .add-step-form input, .steps-section .add-step-form textarea, .steps-section .add-step-form select {
    margin-bottom: 10px;
  }
  
  .steps-section .add-step-form button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .steps-section .add-step-form button:hover {
    background-color: #218838;
  }
  