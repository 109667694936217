.add-spot-container, .edit-spot-container {
    display: flex;
  }
  
  .add-spot-content, .edit-spot-content {
    margin-left: 20%;
    padding: 20px;
    width: 100%;
  }
  
  .add-spot-content h2, .edit-spot-content h2 {
    margin-bottom: 20px;
  }
  
  .add-spot-content form, .edit-spot-content form {
    display: flex;
    flex-direction: column;
  }
  
  .add-spot-content form label, .edit-spot-content form label {
    margin-bottom: 10px;
  }
  
  .add-spot-content form input, .edit-spot-content form input,
  .add-spot-content form select, .edit-spot-content form select,
  .add-spot-content form textarea, .edit-spot-content form textarea {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
  }
  
  .add-spot-content form > button, .edit-spot-content form > button {
    padding: 10px 15px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .add-spot-content form > button:hover, .edit-spot-content form > button:hover {
    background-color: #0056b3;
  }